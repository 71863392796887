import { graphql } from "gatsby"
import * as React from "react"
import { Layout } from "../components/Layout"
import { BlockContent } from "../components/BlockContent"
import { motion, useAnimation } from "framer-motion"
import styled from "@emotion/styled"
import { css, Global } from "@emotion/react"
import Icon, { StyledIcon } from "../components/Icon"
import tw from "twin.macro"

export const query = graphql`
  fragment translatableRichText on SanityRichTextTranslation {
    _type
    en: _rawEn
    sv: _rawSv
    fi: _rawFi
    nb: _rawNb
    da: _rawDa
  }
  fragment translatable on SanityTranslation {
    _type
    en
    sv
    fi
    nb
    da
  }
  fragment filterFragment on SanityFilter {
    __typename
    _key
    variant
    alcoholicContent
    appPlatform
    appVersion {
      operator
      version
    }
    endDate
    endTime
    startDate
    startTime
    membership
    types
    venues {
      id
    }
  }
  fragment tvPageBlocksFragment on SanityTvPage {
    layouts {
      _key
      duration {
        ms
        input
      }
      transition
      blocks {
        __typename
        ...videoFragment
        ...carouselFragment
        ...verticalGroupFragment
        ...horizontalGroupFragment
        ...textBlockFragment
        ...richTextBlockFragment
        ...imageBlockFragment
        ...overlineFragment
        ...powerBiBlockFragment
        ...workplaceBlockFragment
        ...userBlockFragment
      }
    }
  }

  query ($regex: String!) {
    sanityTvPage(_id: { regex: $regex }) {
      _id
      name
      ...tvPageBlocksFragment
    }
  }
`

const Layouts = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const CountdownWrapper = styled.div`
  bottom: 0.5rem;
  left: 0.5rem;
  position: fixed;
  background: rgba(0, 0, 0, 0.37);
  padding: 0.5rem;
  border-radius: 50%;
  z-index: 9999;
  .progress {
    position: relative;
    width: 40px;
    height: 40px;
    path {
      fill: none;
      stroke: rgba(255, 255, 255, 0.88);
    }
  }
`

function Countdown({ onClick, label, playing, index, from }) {
  // const [endTime, setEndTime] = React.useState(
  //   from ? new Date(new Date().valueOf() + from).valueOf() : 0
  // )
  // const [time, setTime] = React.useState(0)
  const controls = useAnimation()

  React.useEffect(() => {
    if (!playing) {
      controls.stop()
      controls.set({
        pathLength: 0,
      })
    } else {
      controls.start({
        pathLength: 1,
        transition: { ease: "linear", duration: from / 1000 },
      })
    }
  }, [controls, from, playing])

  React.useEffect(() => {
    if (from) {
      // setEndTime(new Date(new Date().valueOf() + from).valueOf())
      controls.set({
        pathLength: 0,
      })
      controls.start({
        pathLength: 1,
        transition: { ease: "linear", duration: from / 1000 },
      })
    }
  }, [controls, from, index])

  // React.useEffect(() => {
  //   const onInterval = () => {
  //     if (new Date().valueOf() < endTime) {
  //       setTime(new Date().valueOf() - endTime)
  //     }
  //   }
  //   const interval = setInterval(onInterval, 100)
  //   onInterval()

  //   return () => {
  //     console.log("clearing")
  //     clearInterval(interval)
  //   }
  // }, [endTime])

  // console.log("time", time)

  // if (!time) {
  //   return null
  // }

  return (
    <CountdownWrapper onClick={onClick}>
      {/* {time} */}
      <div css={tw`relative`}>
        <div css={tw`w-full h-full absolute flex justify-center items-center`}>
          <div css={tw`text-white`}>{label}</div>
        </div>
        <svg className="progress" style={{}} viewBox="0 0 50 50">
          <motion.path
            animate={controls}
            strokeWidth="5"
            strokeDasharray="0 1"
            d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
            initial={{ pathLength: 0 }}
            // animate={{ pathLength: 1 }}
            // transition={{
            //   duration: from / 1000,
            //   type: "spring",
            //   damping: 10,
            //   stiffness: 100,
            // }}
            style={{
              rotate: 90,
              translateX: 5,
              translateY: 5,
              scaleX: -1, // Reverse direction of line animation
            }}
          />
        </svg>
      </div>
    </CountdownWrapper>
  )
}

const Buttons = styled.div`
  background: rgba(0, 0, 0, 0.87);
  border-radius: 3vw;
  display: flex;
  gap: 0.5rem;
`
const ControlsWrapper = styled.div`
  z-index: 99;
  position: fixed;
  bottom: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  button {
    padding: 0.5rem;
    ${StyledIcon} {
      fill: white;
      height: 3vw;
      max-height: 48px;
      min-height: 24px;
    }
  }
`

function Controls({ onNext, onPrev, onPlay, onPause }) {
  return (
    <ControlsWrapper>
      <Buttons>
        <button onClick={onPrev}>
          <Icon icon="mdiChevronLeft" />
        </button>
        <button onClick={onPlay}>
          <Icon icon="mdiPlay" />
        </button>
        <button onClick={onPause}>
          <Icon icon="mdiPause" />
        </button>
        <button onClick={onNext}>
          <Icon icon="mdiChevronRight" />
        </button>
      </Buttons>
    </ControlsWrapper>
  )
}

const none = {
  open: { visibility: "visible" },
  closed: { visibility: "hidden" },
}
const fade = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
}
const slideUp = {
  open: { y: 0, opacity: 1 },
  closed: { y: "-100%" },
}
const slideDown = {
  open: { y: 0, opacity: 1 },
  closed: { y: "100%" },
}
const transitions = {
  none,
  fade,
  slideUp,
  slideDown,
}

const TvPage = ({ data: { sanityTvPage }, location }) => {
  const { layouts: allLayouts } = sanityTvPage
  const layouts = allLayouts.filter(
    l => l.duration?.ms && parseInt(l.duration?.ms) >= 5000
  )
  const [playing, setPlaying] = React.useState(true)
  const [controls, setControls] = React.useState(false)
  const [currentLayoutIndex, setCurrentLayoutIndex] = React.useState(0)
  const [timer, setTimer] = React.useState(0)
  const currentLayout = layouts[currentLayoutIndex]
  const nextIndex = (currentLayoutIndex + 1) % layouts.length
  const prevIndex = (currentLayoutIndex + layouts.length - 1) % layouts.length
  const nextLayout = layouts[nextIndex]

  React.useEffect(() => {
    if (!playing || layouts?.length === 1) {
      return
    }
    setTimer(currentLayout?.duration?.ms)
    const timeout = setTimeout(() => {
      setCurrentLayoutIndex(nextIndex)
    }, parseInt(currentLayout?.duration?.ms))

    return () => {
      clearTimeout(timeout)
    }
  }, [
    currentLayoutIndex,
    currentLayout?.duration?.ms,
    layouts?.length,
    timer,
    nextIndex,
    playing,
  ])

  if (!layouts?.length) {
    return "No layouts to display"
  }

  return (
    <Layout location={location}>
      <Global
        styles={css`
          html {
            overflow: hidden;
          }
        `}
      />
      <Countdown
        playing={playing}
        onClick={() => {
          setControls(!controls)
          setPlaying(true)
        }}
        index={currentLayout?._key}
        label={controls && currentLayout?.duration.input}
        from={parseInt(timer || currentLayout?.duration?.ms, 10)}
      />
      {controls && (
        <Controls
          onNext={() => setCurrentLayoutIndex(nextIndex)}
          onPrev={() => setCurrentLayoutIndex(prevIndex)}
          onPause={() => setPlaying(false)}
          onPlay={() => setPlaying(true)}
        />
      )}
      <Layouts>
        {layouts.map(layout => (
          <motion.div
            key={layout._key}
            animate={currentLayout?._key === layout._key ? "open" : "closed"}
            variants={transitions[nextLayout?.transition] || transitions.none}
            transition={{
              type: "tween",
            }}
            style={{
              position: "absolute",
              overflow: "hidden",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <BlockContent blocks={layout.blocks} />
          </motion.div>
        ))}
      </Layouts>
    </Layout>
  )
}

export default TvPage
