import I from "@mdi/react"
import {
  mdiPen,
  mdiChevronRight,
  mdiChevronLeft,
  mdiPlay,
  mdiPause,
  mdiFileQuestion,
} from "@mdi/js"
import styled from "@emotion/styled"

export const StyledIcon = styled(I)`
  height: 24px;
  fill: rgba(66, 66, 66, 1);
`

const icons = {
  mdiPen,
  mdiChevronRight,
  mdiChevronLeft,
  mdiPlay,
  mdiPause,
  mdiFileQuestion,
}

/**
 * Icon component
 * @param {Object} props
 * @param {icons} props.icon
 */
const Icon = ({
  icon,
  primary,
  secondary,
  color,
  error,
  inherit,
  className,
  ...props
}) => {
  // eslint-disable-line no-unused-vars, react/prop-types
  if (error) {
    console.log("error", error)
  }
  if (!icon || !icons[icon]) {
    console.warn("icon not found:", icon)
  }
  const c = !primary && !secondary && color ? color : "inherit"
  return (
    <StyledIcon
      {...props}
      color={c}
      path={icon && icons[icon] ? icons[icon] : icons.mdiFileQuestion}
    />
  )
}

export default Icon
